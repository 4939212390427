import Vue from 'vue'
import {
  Message,
  MessageBox,
  Button,
  Form,
  FormItem,
  Input,
  Radio
} from 'element-ui'

Vue.use(Radio)
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
