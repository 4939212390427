<template>
  <div id="app">
    <router-view></router-view>
    <iframe
      id="iframe-page"
      style="display: none"
      :src="tokenIframeUrl"
      frameborder="0"
    ></iframe>
  </div>
</template>

<script>

  export default {
    name: 'app',
    data() {
      return {}
    },
    computed: {
      tokenIframeUrl(){
        return process.env.VUE_APP_STORAGE
      }
    },
    methods: {}
  }
</script>

<style>
</style>
